import React, { useEffect, useState } from 'react';

const bodyParts = [
    // upper body
    'shoulder',
    'chest',
    'triceps',
    'biceps',
    'legs',
    'upper_back',
    // lower body
    'lower_back',
    'abs',
]

const exercises = {
    'shoulder': [
        { name: 'overhead_pushup', reps: '20-25', time: 5 },
        { name: 'shoulder_pulse', reps: '20-25', time: 5 },
        { name: 'pike_pushups', reps: '10-15', time: 5 },
        { name: 'arm_circles', reps: '30-45 seconds', time: 5 }
    ],
    'chest': [
        { name: 'regular_push_ups', reps: '20-25', time: 5 },
        { name: 'diamond_push_ups', reps: '10-15', time: 5 },
        { name: 'archer_push_ups', reps: '10-15', time: 5 },
        { name: 'wide_push_ups', reps: '10-15', time: 5 },
        { name: 'decline_push_ups', reps: '15-20', time: 5 }
    ],
    'triceps': [
        { name: 'regular_push_ups', reps: '20-25', time: 5 },
        { name: 'diamond_push_ups', reps: '10-15', time: 5 },
        { name: 'tricep_dips_on_chair', reps: '15-20', time: 5 }
    ],
    'biceps': [
        { name: 'door_frame_curls', reps: '20-25', time: 5 },
        { name: 'isometric_bicep_hold', reps: '30-45 seconds', time: 5 }
    ],
    'legs': [
        { name: 'squats', reps: '20-25', time: 5 },
        { name: 'sumo_squats', reps: '20-25', time: 5 },
        { name: 'lunges', reps: '20-25', time: 5 },
        { name: 'jump_squats', reps: '15-20', time: 5 },
        { name: 'calf_raises', reps: '20-25', time: 5 }
    ],
    'upper_back': [
        { name: 'reverse_snow_angles', reps: '20-25', time: 5 },
        { name: 'y_raises', reps: '20-25', time: 5 },
        { name: 'prone_t', reps: '15-20', time: 5 }
    ],
    'lower_back': [
        { name: 'superman', reps: '20-25', time: 5 },
        { name: 'bird_dogs', reps: '15-20', time: 5 },
        { name: 'bridge', reps: '15-20', time: 5 }
    ],
    'abs': [
        { name: 'crunches', reps: '20-25', time: 5 },
        { name: 'plank', reps: '30-60 seconds', time: 5 },
        { name: 'bicycle_crunches', reps: '20-25', time: 5 },
        { name: 'leg_raises', reps: '15-20', time: 5 }
    ]
};

const maxSets = 10;
const maxSetsSpan = Array.from({ length: maxSets }, (_, index) => index + 1);

function App() {
    const [data, setData]=useState();
    const [sets, setSets]=useState(4);
    const [formFilled, setFormFilled]=useState(false);
    const [customExercise, setCustomExercise]=useState();

    useEffect(()=>{
        if (hasTrueValue(data)) { setFormFilled(true); }
        else setFormFilled(false);
    },[data])

    const getRandomExercise = (exercises) => {
        const randomExercise = exercises[Math.floor(Math.random() * exercises.length)];
        return randomExercise;
    }
    const hasTrueValue = (obj) => {
        for (let key in obj) {
          if (obj[key] === true) {
            return true;
          }
        }
        return false;
    }
    const handleForm = (e) => {
        e.preventDefault()
        const bodyPartsInExcercise = Object.keys(data).filter(key => data[key]);
        console.log(bodyPartsInExcercise);

        const selectedExercises = bodyPartsInExcercise.reduce((acc, part) => {
            acc[part] = getRandomExercise(exercises[part]);
            return acc;
        }, {});
        setCustomExercise(selectedExercises);
    }
    const handleCheck = (e) => {
        setData({
            ...data,
            [e.target.name]:e.target.checked
        })
    }
    const handleRange = (e) => {
        setSets(e.target.value);
    }
    const setDone = () => {
        alert('Nice job! Come back soon 💪')
        window.location.href='https://buy.stripe.com/9AQ6q04Lf2w0cWA4gk'
        setCustomExercise(false)
        setFormFilled(false)
        setData(false)
    }

    return (
        <div className="flex flex-col items-center bg-base-100">
            <main className="flex flex-col items-center gap-3 p-2 md:p-8 w-full">
                {!customExercise&&<h1 className="text-3xl sm:text-5xl md:text-7xl font-extrabold pt-24 pb-8 md:pb-16">💪 Make Workout</h1>}
                {customExercise ?
                <div className="card bg-base-300 w-full max-w-96 shadow-xl">
                    <div className="card-body items-center text-center">
                        {Object.keys(customExercise).map((exercise,partOfBody)=>{
                            return <>
                                <h2 className="card-title">
                                    {exercise.toUpperCase()}
                                </h2>
                                <div>
                                    <span className=''>{sets} x {customExercise[exercise]['reps']} </span>
                                    {customExercise[exercise]['name'].replaceAll('_',' ').toUpperCase()}
                                </div>
                                <div className='divider'></div>
                            </>
                        })}
                        <div className="card-actions">
                            <button className="btn btn-primary" onClick={setDone}>💪 DONE</button>
                        </div>
                    </div>
                </div>
                :
                <div className="card bg-base-300 w-96 shadow-xl">
                    <div className="card-body items-center text-center">
                        <form onSubmit={handleForm} className='flexs flex-cols'>
                            <div className='divider'>WHAT DO YOU WANT TO TRAIN?</div>
                            <div className='flex flex-wrap'>
                                {bodyParts.map(part=>
                                    <label className="label cursor-pointer">
                                        <input type="checkbox" name={part.replace(' ','_')} className="mr-1 checkbox checkbox-primary" onChange={handleCheck}/>
                                        <span className="label-text">{part.toUpperCase()}</span>
                                    </label>    
                                )}
                            </div>
                            <div className='divider'>HOW MANY SETS?</div>
                            <div>
                                <input type="range" min={1} max={10} value={sets} className="range range-primary" onChange={handleRange}/>
                                <div className="flex w-full justify-between px-2 text-xs">
                                    {maxSetsSpan.map((number) => (
                                        <span key={number}>{number} </span>
                                    ))}
                                </div>
                            </div>
                            <div className='divider'></div>
                            <button className='btn btn-primary' type="submit" disabled={!formFilled}>💪 LET'S GO</button>
                        </form>
                    </div>
                </div>
                }
                <a href="https://buy.stripe.com/9AQ6q04Lf2w0cWA4gk" className='btn btn-primary btn-outline mt-5' target='_blank'>to the GYM</a>
            </main>
        </div>
    );
}

export default App;
